
import React from "react";
import "../App.css";
import "./Contact.module.css";
 
const Contact = () => {
    return (
        <div>
            <h1>
                Contact Protoduck.
            </h1>
            <p class="center-text">
                Email: <a href = "mailto: hello@protoduck.se"> hello@protoduck.se</a></p>

<p>                Business address: 
Protoduck i Dalarna AB
Hyttingsgränd 2,
784 61 Borlänge,
Sweden

            </p>
        </div>
    );
};
 
export default Contact;