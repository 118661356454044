
import React from "react";
import "../App.css";
 
const About = () => {
    return (
        <div>
            <h1>
                About Protoduck.
            </h1>
            <p>
            Protoduck, based in Borlänge, Sweden, is your trusted partner in pioneering electronic prototypes. While we call Borlänge home, our reach extends far beyond geographical boundaries. We harness the power of remote collaboration to serve clients from every corner of the globe.

With a commitment to excellence and innovation, we bring your electronic ideas to life, no matter where you are. Our dedicated team is equipped to work seamlessly and efficiently, ensuring your vision becomes a tangible reality, regardless of location. At Protoduck, we bridge the gap between creativity and distance, making electronic dreams come true worldwide.
            </p>
            <h2>Protoduck - Your Partner in Innovative Electronics Solutions</h2>
            <p>
            At Protoduck, we are passionate about turning your ideas into reality. We specialize in developing electronic prototypes, taking them from concept to prototype, and guiding them all the way to full-scale production. Our comprehensive range of services encompasses not only the technical aspects but also our unwavering commitment to sustainability. 
            </p>
            <h2>Our Approach: From Ideas to Sustainable Reality</h2>
            <p>
            At the heart of our philosophy is the drive to transform your ideas into tangible electronic prototypes. We understand the journey from concept to market, and that's why we're here to support you at every step. Our team of skilled engineers and designers collaborate closely with you to bring your vision to life. But it doesn't stop there. We're not just focused on creating innovative products; we're equally dedicated to upholding sustainability throughout the entire process.
            </p>
            <h2>Sustainability matters.</h2>
            <p>
            We recognize the importance of responsible electronics development. That's why we meticulously select components that adhere to the highest sustainability standards. From ideation to production, every phase is infused with eco-conscious decisions, ensuring your products are not only cutting-edge but also environmentally friendly.
            </p>
        </div>
    );
};
 
export default About;