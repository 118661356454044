import React from "react";
import logo from "./protoduck_logo.png";
import "./App.css";
import Navbar from "./Navbar";
// import { React } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import OurServices from "./pages/OurServices";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Navbar />
      <Router>
        <Routes>
          <Route exact path="/" exact element={<Home />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
