
import React from "react";
import "../App.css";
 
const OurServices = () => {
    return (
        <div>
            <h1>
                Services.
            </h1>
            <p><b>Electronic Prototyping with rapid revisions. </b> We specialize in crafting functional prototypes that capture your concept's essence and pave the way for successful product development. We excel in delivering prototype series with fast revisions. Our agile approach ensures quick adaptation to evolving ideas, speeding up development while maintaining top-notch quality. Stay ahead in the innovation race with our rapid prototyping expertise. Contact us to accelerate your electronic project.</p>

<p><b>Design and Development. </b>Our experienced team transforms your ideas into comprehensive designs, laying a robust foundation for the journey ahead.</p>

<p><b>Coding and Software Development. </b>Seamless integration of software is crucial in modern electronics. Our coding experts ensure your product's intelligence and functionality are second to none.</p>

<p><b>Production Support. </b>We offer end-to-end support during the production phase, ensuring your product is manufactured efficiently and to the highest quality standards.</p>

<p><b>Sustainability Consultation. </b>Our sustainability experts guide you in making environmentally conscious choices throughout the development process.</p>

<p><b>Quality Assurance. </b>We rigorously test and validate each prototype and final product to guarantee performance, reliability, and safety.</p>

<p><b>Post-launch Support. </b>Our commitment doesn't end when your product hits the market. We provide ongoing support to address any challenges and ensure your product's longevity.</p>

<p>Whether you're a startup with a groundbreaking idea or an established company seeking innovative electronics solutions, Protoduck is your partner of choice. Contact us today to embark on a journey of creativity, sustainability, and excellence in electronics.

            </p>
        </div>
    );
};
 
export default OurServices;